<template>
  <section class="flex min-h-screen flex-col bg-gray-50">
    <AppHeader class="sticky top-0 bg-gradient-to-b from-white to-gray-200" />
    <main
      role="main"
      class="mx-auto w-full max-w-screen-2xl flex-1"
      :class="{ 'bg-white': !$route.path.split('/').includes('teacher') }"
    >
      <Toast />
      <NuxtPage />
    </main>
  </section>
</template>

<script>
export default {
  middleware: 'auth'
}
</script>
